import React from 'react';
import './App.css';



const TypeformLink = "https://samballantyne.typeform.com/to/nfeiPE";

function App() {
  return (
    <div>
      
      {/* nav header */}
      
      <nav style={{ display: 'flex', alignItems: 'baseline', padding: 12  }}>
        <div style={{ flexGrow: 1, fontWeight: 900, fontSize: 20 }}><span role="img" aria-label="fake-logo">🔥</span> Bonfire</div>
        <a href={TypeformLink} style={{ fontSize: 14, fontWeight: 700, backgroundColor: 'black', color: 'white', padding: '5px 12px' }}>APPLY NOW</a>
      </nav>
      
      {/* end of nav header*/}
      
      
      {/* hero image section */}
      
      <section style={{ background: 'url(./bg1.jpg) no-repeat center right', backgroundSize: 'cover', height: 'calc(100vh - 48px)', position: 'relative' }}>
      
        <div style={{ position: 'absolute', left: '10vw', bottom: '15vh', maxWidth: '70%' }}>
          <div style={{ backgroundColor: 'black', color: 'white', fontWeight: 700, padding: 40, fontSize: 32 }}>The next Samin Nosrat is throwing a party.<br/>She’s looking for someone to host.</div>
          <div style={{ textAlign: 'right' }}>
            <a href={TypeformLink} style={{ backgroundColor: 'white', color: 'black', fontWeight: 700, padding: '10px 24px', fontSize: 20, position: 'relative', top: -15, right: -75 }}>APPLY NOW</a>
          </div>
        </div>
        
      </section>
      
      {/* end of hero image section */}
      
      {/* what is Bonfire section */}
      <section style={{ backgroundColor: 'black', color: 'white', padding: '20%', paddingTop: 100, paddingBottom: 100 }}>
        
        <div style={{ opacity: 0.3, fontWeight: 700, fontSize: 60 }}>What is Bonfire?</div>
        <div style={{ fontSize: 40 }}>We find hosts for up-and-coming talent looking to put together special events.</div>
        
      </section>
      {/* end of what is Bonfire */}
      
      
      {/* how it works */}
      
      <section style={{ padding: '20%', paddingTop: 60, paddingBottom: 100 }}>
        
        <div style={{ fontSize: 60, opacity: 0.1, fontWeight: 700, textTransform: 'uppercase' }}>How it works</div>
        
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ maxWidth: 300, paddingRight: 40 }}>
            <div style={{ fontSize: 18, fontWeight: 700, textTransform: 'uppercase', marginTop: 30, marginBottom: 5 }}>Apply</div>
            <span>Sign up. You'll give us some details about your space and the kinds of events you’d like to host.</span>
          </div>
          <div style={{ maxWidth: 300, paddingRight: 40 }}>
            <div style={{ fontSize: 18, fontWeight: 700, textTransform: 'uppercase', marginTop: 30, marginBottom: 5 }}>Receive Proposals</div>
            <p>When we think there’s a good fit, we’ll email you an event proposal.</p>
            <p>It's usually takes 1-2 weeks before you get your first proposal.</p>
          </div>
          <div style={{ maxWidth: 300, paddingRight: 40 }}>
            <div style={{ fontSize: 18, fontWeight: 700, textTransform: 'uppercase', marginTop: 30, marginBottom: 5 }}>Review & Connect</div>
            <span>You’ll be able to review the proposal and talk with the talent before making any commitments.</span>
          </div>
        </div>
        
      </section>
      
      {/* end of "how it works" */}
      
      
      {/* pull quote */}
      <section style={{ background: 'url(./bg3.jpg) no-repeat center center', backgroundSize: 'cover', height: 400, position: 'relative' }}>
        
        <div style={{ position: 'absolute', right: '5%', bottom: '5%' }}>
          <div style={{ backgroundColor: 'black', color: 'white', fontWeight: 700, padding: 40, fontSize: 32 }}>"The most fun I've had in years."</div>
        </div>

      </section>
      {/* end of pull quote section */}
      
      {/* value props */}
      <section style={{ position: 'relative' }}>
        
        
        <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '50%', background: 'url(./bg4.jpg) no-repeat center center', backgroundSize: 'cover'}}>
        </div>
  
        <div style={{ marginLeft: '50%', width: '50%', padding: 20, paddingTop: 100, paddingBottom: 100 }}>
          
          <div>
            <h3>The next generation of stars.</h3>
            <span>Work with stars who are going to be famous next year.</span>
          </div>
  
          <div>
            <h3>Private & safe.</h3>
            <span>All our talent is individually vetted, and we maintain references for everyone.</span>
          </div>
  
          <div>
            <h3>We’re here to hold your hand.</h3>
            <span>Type something here so that I can see how it looks when it’s all typed out.</span>
          </div>
          
          <div style={{ margin: '40px 0 20px 0' }}>
            <a href={TypeformLink} style={{ backgroundColor: 'black', color: 'white', fontWeight: 700, padding: '10px 24px', fontSize: 20  }}>APPLY NOW</a>
          </div>
          
        </div>
  
      
      </section>
      {/* end of value props */}
      
      
      {/* footer */}
      
      <section style={{ height: 200, backgroundColor: 'black', color: 'white', padding: 30, textAlign: 'left' }}>
        
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          
          <div style={{ maxWidth: 200, fontSize: 16, fontWeight: 900 }}>
            <div>Bonfire</div>
          </div>
  
          <div style={{ maxWidth: 200 }}>
          {/* NOTE: spacer*/}
          </div>
          
          <div style={{ maxWidth: 200, fontSize: 12, lineHeight: 2 }}>
            <div>About Us</div>
            <div>Careers</div>
            <div>Press</div>
            <a href={ `mailto:hello@bonfire.events` } style={{ color: 'white' }}>Contact</a>
          </div>
  
          <div style={{ maxWidth: 200, fontSize: 12, lineHeight: 2 }}>
            <div>Terms</div>
            <div>Privacy</div>
          </div>
  
          <div style={{ maxWidth: 200 }}>
            {/* NOTE: spacer*/}
          </div>

        </div>
      
      
      </section>
      
      {/* end of footer section */}
      
    </div>
  );
}

export default App;
